import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, Link, navigate } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Layout, SEO } from '../components';
import { useMediaQuery } from 'usehooks-ts';
import DocumentsList from '../components/documents/DocumentsList';
import Clouds from '../assets/images/clouds.svg';
import FolderIcon from '../assets/icons/folder.svg';
import ChevronIcon from '../assets/icons/chevron.svg';
import PlusIcon from '../assets/icons/plus.svg';
import LockIcon from '../assets/icons/lock.svg';
import LockSubIcon from '../assets/icons/sub-lock.svg';
import UnlockIcon from '../assets/icons/open-lock.svg';
import UnlockSubIcon from '../assets/icons/open-sub-lock.svg';
import VideoPlayer from '../components/VideoPlayer';
import TopicTable from '../components/TopicTable';


import { CountdownTimer } from '../components/UI/FlipClock';
import { useSelector } from 'react-redux';

const ModuleSubCategoryPage = ({
    data,
    data: {
        timers,
        subCategoryPage: {
            module,
            heading,
            description,
            description2,
            subCategoryList,
            seo,
            seoKeywords,
            topicTables,
            schemaMarkup,
            subscriptionforumbuttons,
            video,
            videos,
            youtubeVideo,
        },
    },
}) => {
    process.env.NODE_ENV == 'development' && console.log('🚀 module-sub-category.js', data);
    const screen479 = useMediaQuery('(max-width: 479px)');
    const [openSections, setOpenSections] = useState([]);

    const showSubscriptionButton = subscriptionforumbuttons?.[0]?.displaysubscription ?? false;
    const showForumButton = subscriptionforumbuttons?.[0]?.displayforum ?? false;
    const subscriptionBackgroundColor =
        subscriptionforumbuttons?.[0]?.subbackgroundcolor?.hex ?? '#23D4FF';
    const subscriptionTextColor = subscriptionforumbuttons?.[0]?.subtextcolor?.hex ?? '#000000';
    const forumBackgroundColor =
        subscriptionforumbuttons?.[0]?.forumbackgroundcolor?.hex ?? '#FD99C4';
    const forumTextColor = subscriptionforumbuttons?.[0]?.forumtextcolor?.hex ?? '#000000';

    const formattedTables = topicTables.map(({ table, title, description, featuredDocuments }) => ({
        title,
        description,
        featuredDocuments,
        table: JSON.parse(table)
    }));

    const orderedTimers = React.useMemo(() => {
        return timers?.group?.map(({ edges, examBoard }) => {
            const groups = {};
            for (let {
                node,
                node: { title },
            } of edges) {
                title = title.trim();
                const regex = /\d([A-Za-z])(?![A-Za-z])/;
                const matched = title?.match(regex);

                if (!matched) {
                    if (!groups.unmatched) groups.unmatched = [];
                    groups.unmatched = [
                        ...groups.unmatched,
                        {
                            ...node,
                            title,
                        },
                    ];
                    continue;
                }

                const [_, letter] = matched;
                if (!groups[letter]) {
                    groups[letter] = [];
                }
                groups[letter].push({
                    ...node,
                    title,
                });
            }
            const formattedEdges = Object.values(groups)
                .map((papers) =>
                    papers.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
                )
                .flat();
            return {
                edges: formattedEdges,
                examBoard,
            };
        });
    }, [timers]);

    const user = useSelector((state) => state.user.data);
    const isSubscribed = user?.subscribed ?? false;

    const numOfTimers = orderedTimers.reduce((total, { edges }) => (total += edges?.length ?? 0), 0);

    useEffect(() => {
        if (window.location.hash) {
            const hashTags = window.location.hash.replace('#', '').split(',');
            setOpenSections(hashTags);
        }
    }, []);

    const renderSubCategoryHeading = (subCategoryHeading, index) => (
        <li className="category-breadcrumb" key={subCategoryHeading.id}>
            {index !== 0 && <img src={ChevronIcon} className="category-breadcrumb-image" alt="Chevron" />}
            <h3>{subCategoryHeading.name}</h3>
        </li>
    );

    const renderSubCategoryOptions = (optionHeaderSlug, subCategoryOption) => {
        const containsSubscription = subCategoryOption?.containsSubscription ?? false;
        const isLocked = subCategoryOption.isLocked;
        // const lockedLink = isLocked ? subCategoryOption.slug : subCategoryOption.slug;
        const link = optionHeaderSlug
            ? `${optionHeaderSlug}/${subCategoryOption.slug}`
            : subCategoryOption.slug;
        // const link = `${optionHeaderSlug}/${subCategoryOption.slug}`;
        return (
            <li className="category-option" key={subCategoryOption.id}>
                <img src={FolderIcon} className="category-option-image" alt="Folder" />
                <div className="category-option-text">
                    <Link to={link} className="category-option-link">
                        {subCategoryOption.name}
                    </Link>
                </div>
                {containsSubscription && isSubscribed ? (
                    <img src={UnlockSubIcon} className="lock-icon" alt="Locked" />
                ) : containsSubscription ? (
                    <img
                        src={LockSubIcon}
                        className="lock-icon"
                        alt="Locked with sub content"
                        onClick={() => navigate('/subscriptions')}
                    />
                ) : isLocked ? (
                    <img src={LockIcon} className="lock-icon" alt="Locked" />
                ) : null}
            </li>
        );
    };

    const renderSubCategory = (subCategory, index) => {
        const optionHeaderSlugs = subCategory.subCategoryHeading.map((heading) => heading.slug);
        const optionHeaderSlug = optionHeaderSlugs.join('/');
        const hasManyItems = subCategoryList.length === 1;
        // eslint-disable-next-line
        const [active, setActive] = useState(hasManyItems);
        // const lockedOptions = subCategory.subCategoryLockedOptions.map((option) => ({
        //   ...option,
        //   isLocked: true,
        // }));
        // const subCategoryOptions =
        //   lockedOptions.length > 0
        //     ? subCategory.subCategoryOptionsList.concat(lockedOptions)
        //     : subCategory.subCategoryOptionsList;
        // const sortedSubCategoryOptions =
        //   lockedOptions.length > 0
        //     ? subCategoryOptions.sort((a, b) => a.name.localeCompare(b.name))
        //     : subCategoryOptions;

        const subCategoryOptions = subCategory.subCategoryOptions.map(
            ({ subCategoryOptionHeading, isLocked, containsSubscription }) => ({
                ...subCategoryOptionHeading,
                isLocked,
                containsSubscription,
            }),
        );

        // const sortedSubCategoryOptions = subCategoryOptions.sort((a, b) =>
        //   a.name.localeCompare(b.name),
        // );

        const handleSubCategoryClick = () => {
            setActive(!active);

            if (active) {
                const idx = openSections.findIndex((section) => Number(section) === index);

                if (idx !== -1) {
                    openSections.splice(idx, 1);
                    setOpenSections(openSections);
                    window.history.pushState(null, null, `#${openSections.join(',')}`);
                }
            }

            if (!active) {
                const idx = openSections.findIndex((section) => Number(section) === index);

                if (idx === -1) {
                    openSections.push(index);
                    setOpenSections(openSections);
                    window.history.pushState(null, null, `#${openSections.join(',')}`);
                }
            }
        };

        // eslint-disable-next-line
        useEffect(() => {
            openSections.find((section) => {
                if (Number(section) === index) {
                    setActive(true);
                }
            });
        });

        return (
            <div key={subCategory.id}>
                {subCategory.subCategoryHeading.length > 0 &&
                    renderSubCategoryOptions(null, {
                        isLocked: false,
                        slug: optionHeaderSlug,
                        name: subCategory?.subCategoryHeading?.map(({ name }) => name).join(' > '),
                    })}

                {subCategory.subCategoryHeading.length == 0 && (
                    <div>
                        <ReactMarkdown className="paragraph" children={subCategory.description} />
                        <br />
                        {subCategory.featuredDocument.length > 0 && (
                            <>
                                <h3 className="category-breadcrumb-heading">Featured documents:</h3>
                                <br />
                                <br />
                                <DocumentsList documents={subCategory.featuredDocument} />
                                <br />
                            </>
                        )}
                        <ul className="category-options">
                            {subCategoryOptions.map((option) => {
                                return renderSubCategoryOptions(optionHeaderSlug, option);
                            })}
                        </ul>
                    </div>
                )}
                {/* {!subCategory.subCategoryHeading.length &&
          !subCategory.subCategoryOptionsList.length &&
          subCategory.featuredDocument.length > 0 && (
            <div>
              <h3 className="category-breadcrumb-heading">Featured documents:</h3>
              <br />
              <br />
              <DocumentsList documents={subCategory.featuredDocument} />
            </div>
          )} */}
            </div>
        );
    };

    const hasHeading =
        subCategoryList.length &&
        subCategoryList.map((subCategory) => subCategory.subCategoryHeading.length > 0)[0];

    const categoryCssClasses =
        hasHeading && subCategoryList.length !== 1 ? `w-layout-grid grid-2 categories` : '';

    const subCategoryListFirst = subCategoryList.filter(
        ({ subCategoryHeading, featuredDocument }) => !subCategoryHeading.length,
    );

    const subCategoryListRest = subCategoryList.filter(
        ({ subCategoryHeading }) => subCategoryHeading.length > 0,
    );

    const renderActionButtons = () => {
        if (!showSubscriptionButton && !showForumButton) return;
        return (
            <div className="action-container">
                {showSubscriptionButton && !isSubscribed && (
                    <button
                        onClick={() => navigate('/subscriptions')}
                        className="w-nav-brand sign-in-badge forum-sub-button"
                        style={{
                            backgroundColor: subscriptionBackgroundColor,
                            color: subscriptionTextColor,
                        }}
                    >
                        <span class="2">Join Now</span>
                    </button>
                )}
                {showForumButton && (
                    <button
                        onClick={() => navigate('/forum')}
                        className="w-nav-brand sign-in-badge forum-sub-button"
                        style={{
                            backgroundColor: forumBackgroundColor,
                            color: forumTextColor,
                        }}
                    >
                        <span class="2">Forum</span>
                    </button>
                )}
            </div>
        );
    };

    const allVideos = [
        ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
        ...(youtubeVideo ? youtubeVideo.split(',') : []),
        ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
    ];

    return (
        <MathJaxContext>
            <Layout>
                <SEO
                    description={seo && seo.description}
                    image={seo && seo.image}
                    title={seo && seo.title}
                    seoKeywords={seoKeywords}
                    schema={schemaMarkup && JSON.parse(schemaMarkup)}
                />
                <div>
                    <div className="container">
                        <div className={`srow mobile:x-centered ${numOfTimers < 4 ? 'x-centered' : ''}`}>
                            {!!orderedTimers?.length &&
                                orderedTimers.map(
                                    ({ edges }) =>
                                        !!edges?.length &&
                                        edges.map(({ endDate, title, daysOnly }, i) => (
                                            <div className="column narrow mb-1" key={`clock ${i}`}>
                                                <CountdownTimer endDate={endDate} title={title} daysOnly={daysOnly} />
                                            </div>
                                        )),
                                )}
                        </div>
                        <div className="grid hero" style={{ paddingTop: '50px' }}>
                            <div className="content-block" style={{ minHeight: screen479 ? 'unset' : '630px' }}>
                                {!screen479 && module.image?.url && (
                                    <img
                                        src={module.image.url}
                                        alt={module.image.alt}
                                        style={{ float: 'right', width: '60%' }}
                                    />
                                )}
                                {renderActionButtons()}
                                <h1 className="content-h2">{module.title}</h1>
                                <h2>{heading.name}</h2>
                                {allVideos.length
                                    ? allVideos.map((src) => (
                                        <VideoPlayer src={src} isSmall className="video-player" />
                                    ))
                                    : null}
                                <br />
                                <MathJax hideUntilTypeset="first">
                                    {description && <ReactMarkdown className="paragraph" children={description} />}
                                    {!description && description2 && (
                                        <div dangerouslySetInnerHTML={{ __html: description2 }} />
                                    )}
                                </MathJax>
                            </div>
                            {screen479 && module.image?.url && (
                                <img src={module.image.url} alt={module.image.alt} />
                            )}
                        </div>
                    </div>
                    <img src={Clouds} alt="Clouds pattern" className="pattern-left" />
                </div>
                <div className="content-section">
                    {formattedTables.filter(({ table }) => table?.data).map(({ table, title, description, featuredDocuments }) => (
                        <TopicTable table={table} title={title} description={description} featuredDocuments={featuredDocuments} />
                    ))}

                    <div className="content-block container">
                        {subCategoryListFirst && (
                            <>
                                <br />
                                <br />
                                <div className={categoryCssClasses}>
                                    {subCategoryListFirst.map(renderSubCategory)}
                                </div>
                            </>
                        )}

                        {subCategoryListRest.length > 0 && (
                            <div>
                                <br />
                                <br />
                                <ul className="category-options">{subCategoryListRest.map(renderSubCategory)}</ul>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        </MathJaxContext>
    );
};

export default ModuleSubCategoryPage;

const query = graphql`
  query SubCategoryPageQuery($id: String!, $pathName: String) {
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: $pathName } }, sort: { fields: title }) {
      group(field: examBoard) {
        edges {
          node {
            daysOnly
            endDate
            examBoard
            path
            title
          }
        }
        examBoard: fieldValue
      }
    }
    subCategoryPage: datoCmsModuleSubCategoryPage(id: { eq: $id }) {
      module {
        title
        image {
          alt
          url
        }
      }
      heading {
        name
      }
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      topicTables {
        title
        description
        table
        featuredDocuments {
          title
          fileUrl
        }
        }
      youtubeVideo
      description
      description2
      showSubCategories
      subscriptionforumbuttons {
        displayforum
        displaysubscription
        subbackgroundcolor {
          hex
        }
        subtextcolor {
          hex
        }
        forumbackgroundcolor {
          hex
        }
        forumtextcolor {
          hex
        }
      }
      subCategoryList {
        id
        description
        subCategoryHeading {
          id
          name
          slug
        }
        featuredDocument {
          id: originalId
          name
          displayName
          freeDocument
          freeSample
          hasPassword
          fileUrl
          originalId: id
          module {
            slug
          }
          price
          documentCategory {
            slug
          }
          isSubscription
        }
        # subCategoryOptionsList {
        #   id
        #   name
        #   slug
        # }
        # subCategoryLockedOptions {
        #   id
        #   name
        #   slug
        # }
        subCategoryOptions {
          subCategoryOptionHeading {
            id
            name
            slug
          }
          isLocked
          containsSubscription
        }
      }
      seo {
        description
        title
        image {
          url
        }
      }
      seoKeywords {
        id
        keyword
      }
      schemaMarkup
    }
  }
`;
export { query };
